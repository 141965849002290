import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const executeSearchAsync = createAsyncThunk(
  "content/search",
  async (payload = { query: "", platformData: {} }, { getState }) => {
    try {
      const platformId = payload.platformData?.id || getState().frontend.platformData?.id;
      const response = await axios.get("/api/content/search", {
        params: { q: payload.query, platformId }
      });
      return { ...payload, results: response.data };
    } catch (error) {
      console.log(error);
    }
  }
);

export const searchContentByTagAsync = createAsyncThunk(
  "content/searchContentByTag",
  async ({ tag }, { getState }) => {
    const platformId = getState().frontend.platformData?.id;
    const response = await axios.get("/api/content/by-tag/" + tag + "/" + platformId);
    return response.data;
  }
);

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    loading: false,
    error: null,
    hasFocus: false,
    query: "",
    results: {
      items: []
    },
    resultsByTag: []
  },
  reducers: {
    setSearchQuery: (state, action) => {
      state.query = action.payload;
    },
    setFocus: state => {
      state.hasFocus = true;
    },
    clearSearchQuery: state => {
      state.hasFocus = false;
      state.query = "";
      state.loading = false;
      state.results = {
        items: []
      };
    },
    clearFocus: state => {
      state.hasFocus = false;
    },
    clearErrors: state => {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(executeSearchAsync.pending, state => {
        state.loading = true;
      })
      .addCase(executeSearchAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.hasFocus = !action.payload?.resetFocus;
        state.results = action.payload?.results || { items: [] };
      })
      .addCase(executeSearchAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(searchContentByTagAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.resultsByTag = action.payload.contents;
      });
  }
});

export const { clearError, clearSearchQuery, setSearchQuery, setFocus, clearFocus } =
  searchSlice.actions;
export default searchSlice.reducer;
